import { Button, ButtonTheme, Typography, TypographyType } from '@vivino/js-react-common-ui';
import { generateQueryUrl, useI18N } from '@vivino/js-web-common';
import React from 'react';

import { urlPatterns } from '@lib/appBanner';

import styles from './appBanner.module.scss';

const TRANSLATIONS = {
  buyTheRightWine: 'common.buy_the_right_wine',
  openApp: 'common.open_app',
};
const AppBanner = () => {
  const { t } = useI18N();
  const userAgent = globalThis.navigator?.userAgent;
  const isAndroid = userAgent?.match(/Android/i);

  const regexPatterns = urlPatterns.map(patternToRegex);
  const matches = regexPatterns.some((regex) => regex.test(globalThis.location.pathname));

  function patternToRegex(pattern) {
    const escapedPattern = pattern.replace(/[-/\\^$+?.()|[\]{}]/g, '\\$&');
    const regexPattern = escapedPattern.replace(/\*/g, '.*');
    return new RegExp(`^${regexPattern}$`);
  }

  function isChromeBrowser() {
    const isChromium = globalThis.chrome;
    const isChromeBrowser = userAgent.indexOf('Chrome') > -1;
    const isEdgeOrOperaSamsung =
      userAgent.indexOf('Edg') > -1 ||
      userAgent.indexOf('OPR') > -1 ||
      userAgent.indexOf('SamsungBrowser') > -1;

    return (
      isChromeBrowser && isChromium !== null && isChromium !== undefined && !isEdgeOrOperaSamsung
    );
  }

  if (!isAndroid || !isChromeBrowser() || !matches) {
    return null;
  }
  const buttonUrl = generateQueryUrl({
    baseUrl: `https://app.vivino.com${globalThis.location?.pathname}${globalThis.location?.search}`,
    params: [
      {
        name: 'app_store_url',
        value: globalThis.__GOOGLE_PLAY_URL__,
      },
    ],
  });

  return (
    <div className={styles.container}>
      <a href={buttonUrl} data-testid="appLink">
        <div className={styles.textContainer}>
          <div className={styles.appImage}></div>
          <div className={styles.title}>
            <Typography type={TypographyType.LabelMedium}>
              Vivino: {t(TRANSLATIONS.buyTheRightWine)}
            </Typography>
            <div className={styles.rating}>
              <div className={styles.stars}></div>
              <Typography type={TypographyType.Caption}>188k</Typography>
            </div>
          </div>
          <div>
            <Button theme={ButtonTheme.Primary}>{t(TRANSLATIONS.openApp)}</Button>
          </div>
        </div>
      </a>
    </div>
  );
};

export default AppBanner;
