export const urlPatterns = [
  '/*/*/articles/*',
  '/wineries/*/wines/*',
  '/wineries/*',
  '/wines/*',
  '/wine-countries/*/promos/*',
  '/users/*/activities/*',
  '/users/*/reviews/*',
  '/users/*',
  '/toplists/*',
  '/wine-styles/*',
  '/checkout/vintages/*/prices/*/purchase_orders/new',
  '/checkout/*',
  '/carts',
  '/carts/*',
  '/explore',
  '/market',
  '/merchants/*',
  '/uni/*',
  '/*/w/*',
  '/premium/signup',
  '/premium/signup/*',
  '/premium/redeem',
  '/users/*/purchase_orders',
  '/users/*/purchase_orders/*',
  '/login_universal',
  '/wine-news',
  '/wine-news/*',
  '/adventures',
  '/adventures/*',
  '/cellar',
  '/users/*/rankings',
  '/mixitup',
  '/taste_profile',
  '/referral',
  '/articles/*',
  '/wine_club',
  '/wine_club/*',
  '/scanner/*',
  '/wine-regions/*',
  '/grapes/*',
  '/plus/management',
  '/last-scanned',
  '/last-rated',
  '/f/open-in-app/*',
  '/discover',
  '/dish/*',
];
